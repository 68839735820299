'use strict'

import { Gesture } from '@use-gesture/vanilla'

import { addFocusedBy, removeFocusedBy } from '@design/utilities/helpers/body-focused-by'

export interface ModalConfig {
  rootEl?: HTMLElement
  class?: string
  onOpen?: (modal: Modal) => void
  onClose?: (modal: Modal) => void
  onContentSet?: (modal: Modal) => void
  onDestroy?: (modal: Modal) => void
}

export default class Modal {
  config!: ModalConfig
  element!: HTMLElement
  isOpened!: boolean
  fadeEl!: HTMLElement
  handleEl!: HTMLElement | null
  wrapperEl!: HTMLElement
  closeEls!: HTMLElement[] | null
  contentEl!: HTMLElement
  gesture!: Gesture

  constructor(element: string | HTMLElement, config: ModalConfig = {}) {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element) as HTMLElement
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el

    if (!config.rootEl) {
      config.rootEl = this.element.parentNode as HTMLElement
    }

    this.config = config
    this.isOpened = false

    this.setElements()

    if (this.config.class) {
      this.element.classList.add(this.config.class)
    }

    this.initEvents()

    this.element.setAttribute('data-init', 'true')
  }

  setElements(): void {
    const wrapperEl = this.element.querySelector('.o-modal__wrapper') as HTMLElement
    const fadeEl = this.element.querySelector('.o-modal__fade') as HTMLElement
    const contentEl = this.element.querySelector('.o-modal__content') as HTMLElement

    this.wrapperEl = wrapperEl
    this.fadeEl = fadeEl
    this.contentEl = contentEl
    this.handleEl = this.element.querySelector('.o-modal__handle')
    this.closeEls = Array.from(this.element.querySelectorAll<HTMLElement>('.o-modal__close'))
  }

  getElement(): HTMLElement {
    return this.element
  }

  initEvents(): void {
    if (this.closeEls && this.closeEls.length > 0) {
      this.fadeEl.addEventListener('click', () => {
        this.close()
      })

      this.closeEls.forEach((el) =>
        el.addEventListener('click', () => {
          this.close()
        })
      )

      document.body.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          this.close()
        }
      })
    }

    if (this.handleEl) {
      this.gesture = new Gesture(
        this.handleEl,
        {
          onDragEnd: (state) => {
            if (state.direction[1] === 1) {
              this.toggle()
            }
          },
        },
        {
          drag: {
            axis: 'y',
          },
        }
      )
    }
  }

  open(): Modal {
    if (this.isOpened) {
      return this
    }

    addFocusedBy('modal')

    if (document.body !== this.element.parentNode) {
      document.body.append(this.element)
    }

    this.element.removeAttribute('hidden')

    setTimeout(() => {
      this.element.setAttribute('data-open', 'true')
      this.element.focus()
      this.isOpened = true

      this.element.dispatchEvent(new CustomEvent('modal.opened'))

      if (this.config.onOpen) {
        this.config.onOpen(this)
      }
    }, 0)

    return this
  }

  close(): Modal {
    if (!this.isOpened) {
      return this
    }

    this.element.setAttribute('data-open', 'false')

    removeFocusedBy('modal')
    this.isOpened = false

    this.element.dispatchEvent(new CustomEvent('modal.closed'))

    const transitionEndFn = (): void => {
      if (this.config.rootEl !== this.element.parentNode) {
        this.config.rootEl?.append(this.element)
      }
      this.wrapperEl.removeEventListener('transitionend', transitionEndFn)

      if (this.config.onClose) {
        this.config.onClose(this)
      }
    }

    this.wrapperEl.addEventListener('transitionend', transitionEndFn)

    return this
  }

  setContent(content: string | HTMLElement): Modal {
    if (typeof content === 'string') {
      this.contentEl.innerHTML = content
    } else {
      this.contentEl.appendChild(content)
    }

    if (this.config.onContentSet) {
      this.config.onContentSet(this)
    }

    return this
  }

  toggle(): Modal {
    if (this.isOpened) {
      this.close()
    } else {
      this.open()
    }

    return this
  }

  destroy(): void {
    if (this.config.onDestroy) {
      this.config.onDestroy(this)
    }

    this.config.rootEl?.removeChild(this.element)
  }
}
